import React from 'react'
import { Row, Col } from 'react-bootstrap'
import CampaignMenuExpress from './CampaignMenuExpress'
import TerminalMenuExpress from './TerminalMenu'
import { siteMasterDomainDict, siteImpactReportDict, siteEcfaLogoUrl } from 'modules/siteContents'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

library.add(fab)

export default class Footer extends React.Component {

  // state = {
  //   bigFont: false
  // }

  // Old version till Aug 30 2021
  // render() {
  //   const { bigFont } = this.state
  //   return (
  //     <div className="footer-container">
  //       {
  //         this.props.customer ? null : (
  //           <div className={`footer-upper p3 ${bigFont ? 'big-font' : ''}`}>
  //             <Row>
  //               <Col xs={12} sm={9}>
  //                 <Row>
  //                   <Col xs={6} sm={3}>
  //                     <h6>
  //                       <a href="/who-we-are">
  //                         WHO WE ARE
  //                       </a>
  //                     </h6>
  //                     <ul>
  //                       <li><a href={`${masterDomain}/staff`}>Meet The Staff</a></li>
  //                       <li><a href={`${masterDomain}/pro-life-meaning`}>Pro Life Meaning</a></li>
  //                       <li><a href={`${masterDomain}/in-the-news`}>In The News</a></li>
  //                     </ul>
  //                     <br />
  //                     <a href="https://analytics.excellenceingiving.com/overview/save-the-storks/" target="_blank" rel="noopener noreferrer">
  //                       <img src="https://savethestorks.com/wp-content/uploads/2018/10/Excellence-in-Giving-Certified-Transparent-200X200.png" width="125px" alt="excellence in giving" className="footer_logo" />
  //                     </a>
  //                     <br /><br />
  //                   </Col>
  //                   <Col xs={6} sm={3}>
  //                     <h6>
  //                       <a href={`${masterDomain}/what-we-do`}>
  //                         WHAT WE DO
  //                       </a>
  //                     </h6>
  //                     <ul>
  //                       <li><a href={`${masterDomain}/what-we-do/stork-bus-and-us/`}>Stork Bus and Us</a></li>
  //                       <li><a href={`${masterDomain}/what-we-do/stork-bus-locations/`}>Stork Bus Locations</a></li>
  //                       <li><a href={`${masterDomain}/what-we-do/advocacy-and-awareness/`}>Advocacy and Awareness</a></li>
  //                       <li><a href={`${masterDomain}/what-we-do/fundraise/`}>Fundraise</a></li>
  //                       <li><a href={`${masterDomain}/what-we-do/church/`}>Church</a></li>
  //                       <li><a href={`${masterDomain}/what-we-do/pregnancy-resource-center/`}>Pregnancy Resource Center</a></li>
  //                       <li><a href={`${masterDomain}/resources-for-women/`}>Resources For Women</a></li>
  //                     </ul>
  //                     <br />
  //                     <a href="https://www.guidestar.org/profile/46-1031815" target="_blank" rel="noopener noreferrer">
  //                       <img src="https://widgets.guidestar.org/gximage2?o=9250260&amp;l=v4" alt="gold seal of transparency" className="footer_logo" />
  //                     </a>
  //                     <br /><br />
  //                   </Col>
  //                   <Col xs={6} sm={3}>
  //                     <h6>
  //                       <a href={`${masterDomain}/join-the-movement/`}>
  //                         JOIN THE MOVEMENT
  //                       </a>
  //                     </h6>
  //                     <ul>
  //                       <li><a href={`${masterDomain}/advocate/`}>Advocate</a></li>
  //                       <li><a href={`${masterDomain}/careers/`}>Careers</a></li>
  //                       <li><a href={`${masterDomain}/volunteer/`}>Volunteer</a></li>
  //                       <li><a href={`${masterDomain}/volunteer-driver/`}>Volunteer Drivers</a></li>
  //                       <li><a href={`${masterDomain}/kirk-cameron/`}>Living Room Reset</a></li>
  //                       <li><a href={`${masterDomain}/flyway-conference/`}>Flyway Conference</a></li>
  //                       <li><a href={`${masterDomain}/march_for_life2020/`}>March For Life</a></li>
  //                     </ul>
  //                   </Col>
  //                   <Col xs={6} sm={3}>
  //                     <h6>BE INFORMED</h6>
  //                     <ul>
  //                       <li><a href={`${masterDomain}/subscribe/`}>E-mail Sign Up</a></li>
  //                       <li><a href={`${masterDomain}/contact-us/`}>Contact Us</a></li>
  //                       <li><a href={`${masterDomain}/blog/`}>Blog</a></li>
  //                       <li><a href={`${masterDomain}/store/`}>Store</a></li>
  //                       <li><a href={`${masterDomain}/unplanned/`}>Unplanned Movie</a></li>
  //                       <li><a href={`${masterDomain}/terms/`}>Terms & Conditions</a></li>
  //                       <li><a href={`${masterDomain}/privacy/`}>Privacy Policy</a></li>
  //                       <li><a href={`${masterDomain}/returns/`}>Return Policy</a></li>
  //                       <li><a href={`${masterDomain}/disclaimer/`}>Disclaimer</a></li>
  //                     </ul>
  //                     <br />
  //                     <span className="font_toggle" onClick={() => this.setState({ bigFont: !bigFont })}>
  //                       <img src={`${masterDomain}/wp-content/uploads/2020/02/icons8-lowercase-96_white.png`} width="30" alt="change font size" />
  //                     </span>
  //                   </Col>
  //                 </Row>
  //               </Col>
  //               <Col xs={12} sm={3}>
  //                 <Row>
  //                   <Col xs={12}>
  //                     <img src={`${masterDomain}/wp-content/uploads/2019/04/savethestorks_logo_footer.png`}/>
  //                     <br /><br />
  //                     <p>
  //                       Save the Storks exists to inspire cultural change by shaping compelling pro-life narratives and empowering strategic partners to serve and value every life
  //                     </p>
  //                   </Col>
  //                 </Row>
  //               </Col>
  //             </Row>
  //           </div>
  //         )
  //       }
  //       <div className="footer-lower">
  //         <Row>
  //           <Col xs={12} sm={9} className="footer-copyright">
  //             <div>© Copyright 2021. Save the Storks® is a 501 (c)(3) • EIN: 461031815</div>
  //             <div>
  //               <a href={`${masterDomain}/wp-content/uploads/2019/12/STS.2018.FS_.1.pdf`} target="_blank">
  //                 Audited Financial Report
  //               </a>
  //               &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;
  //               <a href={`${masterDomain}/wp-content/uploads/2019/12/2018.STS_.990.Public.Copy_.pdf`} target="_blank">
  //                 2018 990
  //               </a>
  //               &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;
  //               <a href={`${masterDomain}/wp-content/uploads/2020/04/Save_the_Storks_Impact_Report_2019.pdf`} target="_blank">
  //                 2019 Impact Report
  //               </a>
  //               &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;
  //               <a href={`${masterDomain}/wp-content/uploads/2020/09/Midyear-impact-report-2020.pdf`} target="_blank">
  //                 2020 Mid-Year Impact Report
  //               </a>
  //             </div>
  //           </Col>
  //           <Col xs={12} sm={3}>
  //             <ul className="helpme-footer-social">
  //               <li>
  //                 <a target="_blank" href="https://www.facebook.com/SaveTheStorks">
  //                   <i className="helpme-icon-facebook" />
  //                 </a>
  //               </li>
  //               <li>
  //                 <a target="_blank" href="https://twitter.com/savethestorks">
  //                   <i className="helpme-icon-twitter" />
  //                 </a>
  //               </li>
  //               <li>
  //                 <a target="_blank" href="http://www.pinterest.com/savethestorks">
  //                   <i className="helpme-icon-pinterest" />
  //                 </a>
  //               </li>
  //               <li>
  //                 <a target="_blank" href="http://instagram.com/savethestorks">
  //                   <i className="helpme-icon-instagram" />
  //                 </a>
  //               </li>
  //               <li>
  //                 <a target="_blank" href="https://www.linkedin.com/company/save-the-storks/">
  //                   <i className="helpme-icon-linkedin" />
  //                 </a>
  //               </li>
  //               <li>
  //                 <a target="_blank" href="https://www.youtube.com/channel/UCYu5nomth5OqTOspTyJp0Yg">
  //                   <i className="helpme-icon-youtube" />
  //                 </a>
  //               </li>
  //             </ul>
  //           </Col>
  //         </Row>
  //       </div>
  //     </div>
  //   )
  // }

  render() {
    const { site, isAdminTerminal, stripeTerminals, initialUrlParams, layoutConfig } = this.props
    const masterDomain = siteMasterDomainDict[site]
    if (layoutConfig?.footer === false) {
      return null
    }
    switch(site) {
      case 'EMA': 
        return (<></>)
      case 'OPH': 
        return (
          <div className='oph-footer'>
            <div className='footer-wrapper pt1 pb3'>
              <p>
                Copyright © 2023 Options For Her. All rights reserved.
              </p>
            </div>
          </div>
        )
      case 'VV':
        return (
          <div className='footer-wrapper pt1 pb3'>
					  <Row>
							<Col xs={6}>
								<div className='pl3'>
                  <div>
                    <h3 className='mt3'>Mama Bear Care</h3>
                    <div>
                      <ul id="menu-about-us-footer">
                        <li id="menu-item-21">
                          <a href={`${masterDomain}/who-we-are/`}>Who We Are</a>
                        </li>
                        <li id="menu-item-681">
                          <a href={`${masterDomain}/join-the-village/`}>Join Mama Bear Care</a>
                        </li>
                        <li id="menu-item-709">
                          <a href={`${masterDomain}/resources/`}>Helpful Resources</a>
                        </li>
                        <li id="menu-item-710">
                          <a href={`${masterDomain}/contact/`}>Contact Us</a>
                        </li>
                        <li id="menu-item-45">
                          <a href={`${masterDomain}/blog/`}>Blog</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>							
              </Col>
							<Col xs={6}>
						    <nav className='pr3 mt3 footer-social'>
							    <ul>
								    <li>
                      <a href="https://www.facebook.com/VeritysVillage/">
                        <svg aria-hidden="true" role="img" focusable="false" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 2C6.5 2 2 6.5 2 12c0 5 3.7 9.1 8.4 9.9v-7H7.9V12h2.5V9.8c0-2.5 1.5-3.9 3.8-3.9 1.1 0 2.2.2 2.2.2v2.5h-1.3c-1.2 0-1.6.8-1.6 1.6V12h2.8l-.4 2.9h-2.3v7C18.3 21.1 22 17 22 12c0-5.5-4.5-10-10-10z"></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/veritys_village/">
                        <svg aria-hidden="true" role="img" focusable="false" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12,4.622c2.403,0,2.688,0.009,3.637,0.052c0.877,0.04,1.354,0.187,1.671,0.31c0.42,0.163,0.72,0.358,1.035,0.673 c0.315,0.315,0.51,0.615,0.673,1.035c0.123,0.317,0.27,0.794,0.31,1.671c0.043,0.949,0.052,1.234,0.052,3.637 s-0.009,2.688-0.052,3.637c-0.04,0.877-0.187,1.354-0.31,1.671c-0.163,0.42-0.358,0.72-0.673,1.035 c-0.315,0.315-0.615,0.51-1.035,0.673c-0.317,0.123-0.794,0.27-1.671,0.31c-0.949,0.043-1.233,0.052-3.637,0.052 s-2.688-0.009-3.637-0.052c-0.877-0.04-1.354-0.187-1.671-0.31c-0.42-0.163-0.72-0.358-1.035-0.673 c-0.315-0.315-0.51-0.615-0.673-1.035c-0.123-0.317-0.27-0.794-0.31-1.671C4.631,14.688,4.622,14.403,4.622,12 s0.009-2.688,0.052-3.637c0.04-0.877,0.187-1.354,0.31-1.671c0.163-0.42,0.358-0.72,0.673-1.035 c0.315-0.315,0.615-0.51,1.035-0.673c0.317-0.123,0.794-0.27,1.671-0.31C9.312,4.631,9.597,4.622,12,4.622 M12,3 C9.556,3,9.249,3.01,8.289,3.054C7.331,3.098,6.677,3.25,6.105,3.472C5.513,3.702,5.011,4.01,4.511,4.511 c-0.5,0.5-0.808,1.002-1.038,1.594C3.25,6.677,3.098,7.331,3.054,8.289C3.01,9.249,3,9.556,3,12c0,2.444,0.01,2.751,0.054,3.711 c0.044,0.958,0.196,1.612,0.418,2.185c0.23,0.592,0.538,1.094,1.038,1.594c0.5,0.5,1.002,0.808,1.594,1.038 c0.572,0.222,1.227,0.375,2.185,0.418C9.249,20.99,9.556,21,12,21s2.751-0.01,3.711-0.054c0.958-0.044,1.612-0.196,2.185-0.418 c0.592-0.23,1.094-0.538,1.594-1.038c0.5-0.5,0.808-1.002,1.038-1.594c0.222-0.572,0.375-1.227,0.418-2.185 C20.99,14.751,21,14.444,21,12s-0.01-2.751-0.054-3.711c-0.044-0.958-0.196-1.612-0.418-2.185c-0.23-0.592-0.538-1.094-1.038-1.594 c-0.5-0.5-1.002-0.808-1.594-1.038c-0.572-0.222-1.227-0.375-2.185-0.418C14.751,3.01,14.444,3,12,3L12,3z M12,7.378 c-2.552,0-4.622,2.069-4.622,4.622S9.448,16.622,12,16.622s4.622-2.069,4.622-4.622S14.552,7.378,12,7.378z M12,15 c-1.657,0-3-1.343-3-3s1.343-3,3-3s3,1.343,3,3S13.657,15,12,15z M16.804,6.116c-0.596,0-1.08,0.484-1.08,1.08 s0.484,1.08,1.08,1.08c0.596,0,1.08-0.484,1.08-1.08S17.401,6.116,16.804,6.116z"></path>
                        </svg>
                      </a>
                    </li>
							    </ul>
						    </nav>
                <br /><br />
						    <div className='flex v-center'>
                  <div>
                    <img loading="lazy" src={`${masterDomain}/wp-content/uploads/2020/11/savethestorks_logo_footer.png`} width="40" height="38" alt="sts logo"/>
                    &nbsp;
                  </div>
                  <div>
                    <p>
                      Powered by <br/>
                      <a href="https://savethestorks.com/" target="_blank">Save the Storks</a>
                    </p>
                  </div>
                </div>					
              </Col>
				    </Row>
				  </div>
        )
      case 'FEW':
        return (
          <div className='bg-few-mint footer'>
            {/* <div className='full-width bg-storks-fuchsia center v-center flex hidden-optional' style={{ height: 100 }}>
              <div className='dark flex-1' style={{ fontFamily: 'WhiteOleander', fontSize: '3em', fontWeight: 'bold' }}>
                <span className='white'>p.s.</span>  you've  got  this
              </div>
            </div> */}
            <div style={{ maxWidth: 1000, margin: 'auto', fontSize: 14, padding: '16px 32px' }}>
              <Row className='hidden-optional'>
                <Col xs={12} sm={12} md={3} className='mb3 center-md'>
                  <img width='136' height='136' src='/server/assets/images/Round-Green-Logo_comp-150x150.png' />
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <Row>
                    <Col xs={12} className='mb3 center-md'>
                      <a href="https://www.foreverywoman.org/privacy-policy">PRIVACY POLICY</a>
                    </Col>
                    <Col xs={12} className='mb3 center-md'>
                      <a href="https://www.foreverywoman.org/disclaimer">DISCLAIMER</a>
                    </Col>
                    <Col xs={12} className='mb3 center-md'>
                      <a href="https://www.foreverywoman.org/terms-conditions">TERMS & CONDITIONS</a>
                    </Col>
                    <Col xs={12} className='mb3 center-md'>
                      <a href="https://www.foreverywoman.org/cookie-policy">COOKIE POLICY</a>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} sm={6} md={6} className='mb3 center-md'>
                  <a href="mailto:info@foreverywoman.org" style={{ fontWeight: 700 }}>info@foreverywoman.org</a>
                </Col>
              </Row>
              {/* <div className='mb3 hidden-optional' style={{ fontSize: 30 }}>
                <a href="https://www.instagram.com/_foreverywoman" className='mr2'>
                <FontAwesomeIcon icon="fa-brands fa-instagram" />
                </a>
                <a href='https://www.facebook.com/For-Every-Woman-100150416104332'>
                  <FontAwesomeIcon icon="fa-brands fa-facebook" />
                </a>
              </div> */}
              <div className='mb3'>
                <i>{`© Copyright ${new Date().getFullYear()} For Every Woman® is a 501(c)(3) • EIN: 461031815`}</i>
              </div>
            </div>
          </div>
        )
      default:
        return (
          <div style={{ fontFamily: 'Gotham-Book, Arial, sans-serif !important' }}>
            <div className="bg-darkblue full-width" style={{ height: 40 }}/>
            <div className="footer-container full-width">
              <div className='flex' style={{ maxWidth: '90rem', margin: 'auto' }}>
                <div className='flex-1'>
                  {
                    isAdminTerminal ? null : (
                      <div style={{ lineHeight: 2 }}>
                        <p className="footer-copyright" style={{ fontSize: 'small', fontWeight: 'normal' }}>
                        Thank you for your support of the mission and programs of Save The Storks. We will make every effort to ensure that gifts designated to support specific programs or activities will be used in accordance with your wishes. However, unless a gift is made expressly conditional, Save The Storks and its board will determine how to use your gift in a way that furthers the mission of the organization.
                        </p>
                        <p 
                          className="footer-copyright" 
                          style={{ marginBottom: 0, paddingBottom: 0, fontSize: 'small', fontWeight: 'normal', fontFamily: 'Gotham-Bold, Arial, sans-serif' }}
                        >
                          <strong>Footnotes:</strong>
                        </p>
                        <p className="footer-copyright" style={{ paddingBottom: 10, fontSize: 'small', fontWeight: 'normal' }}>
                          1, 2 - Research Study of the Pro-Life/Pro-Choice Cause (2019, May). Save the Storks. <br/>Retrieved from https://savethestorks.com/wp-content/uploads/2019/10/Onsite-Revised-Save-The-Storks-1.pdf 
                        </p>
                      </div>
                    )
                  }
                  <div style={{ marginRight: '2em' }}>{`© Copyright ${(new Date()).getFullYear()} Save the Storks® is a 501(c)(3) • EIN: 461031815`}</div>
                  <div className='mt2'>
                  {/* <a href={`${masterDomain}${siteImpactReportDict[site]}`} target="_blank" style={{ whiteSpace: 'nowrap' }}>2021 Mid-Year Impact Report</a>
                  <span className='separator'>{'    •    '}</span>
                  <a href={`${masterDomain}/wp-content/uploads/2021/03/impactreport-2020-hl-forweb.pdf`} target="_blank" style={{ whiteSpace: 'nowrap' }}>2020 Impact Report</a>
                  <span className='separator'>{'    •    '}</span>
                  <a href={`${masterDomain}/wp-content/uploads/2021/08/2020-STS-990-Public-Copy.pdf`} target="_blank" style={{ whiteSpace: 'nowrap' }}>2020 990</a>
                  <span className='separator'>{'    •    '}</span>
                  <a href={`${masterDomain}/wp-content/uploads/2021/08/STS-2020-Audited-Financial-Statements.pdf`} target="_blank" style={{ whiteSpace: 'nowrap' }}>2020 Audited Financial Report</a>
                  <span className='separator'>{'    •    '}</span>
                  <a href={`${masterDomain}/wp-content/uploads/2021/03/2019.STS_.990.Public.pdf`} target="_blank" style={{ whiteSpace: 'nowrap' }}>2019 990</a>
                  <span className='separator'>{'    •    '}</span>
                  <a href={`${masterDomain}/terms-2`} target="_blank" style={{ whiteSpace: 'nowrap' }}>Terms</a>
                  <span className='separator'>{'    •    '}</span>
                  <a href={`${masterDomain}/privacy`} target="_blank" style={{ whiteSpace: 'nowrap' }}>Privacy</a>
                  { isAdminTerminal && <span className='separator'>{'    •    '}</span> } */}
                  { isAdminTerminal && <CampaignMenuExpress dropup={true} className="guest-mode footer-dropdown" initialUrlParams={initialUrlParams} />}
                  { isAdminTerminal && !!stripeTerminals.length && <span className='separator'>{'    •    '}</span> }
                  { isAdminTerminal && <TerminalMenuExpress dropup={true} className="guest-mode footer-dropdown" initialUrlParams={initialUrlParams} />}
                  </div>
                </div>
                <div className='ml1'>
                  <a href="#header" style={{ fontFamily: 'Gotham-Book, Arial, sans-serif', WebkitFontSmoothing: 'antialiased' }} className="right mb1">
                    <div style={{ fontWeight: 'normal' }} className="sm-invisible">To the top ↑</div>
                    <div style={{ fontWeight: 'normal' }} className="sm-visible">Top ↑</div>
                  </a>
                  <div className='flex'>
                    <a href='https://www.ecfa.org/MemberProfile.aspx?ID=53345' target='_blank'>
                      <img alt="" src={siteEcfaLogoUrl[site]} width={81} height={81} className="mb1 flex-1"/>
                    </a>
                    &nbsp;
                    <a href="https://workplaces.org/articles/241-organizations-honored-as-certified-best-christian-workplaces-in-2022" target='_blank'>
                      <img alt="" src="https://donate.savethestorks.com/images/best-christian-workplaces.png" width={90} height={81} className="mb1 flex-1"/>
                    </a>
                  </div>
                  <div className='flex'>
                    <a href="https://www.charitynavigator.org/ein/461031815" target='_blank'>
                      <img alt="" src="https://donate.savethestorks.com/images/Four-Star-Rating-Badge-Full-Color.png" width={90} height={81} className="mb1 flex-1" />
                    </a>
                    &nbsp;
                    <a href="https://www.guidestar.org/profile/46-1031815" target='_blank'>
                      <img alt="" src="https://donate.savethestorks.com/images/candid-seal-platinum-2024.png" width={90} height={81} className="mb1 flex-1" />
                    </a>
                  </div>
                  <div className='center'>
                    <a href="https://greatnonprofits.org/org/save-the-storks?search=save%20the%20storks" target='_blank'>
                      <img alt="" src="https://donate.savethestorks.com/images/greatnonprofits.png" width={150} height={70}/>
                    </a>
                  </div>
                  <div className='center'>
                    <a href="https://db.ministrywatch.com/ministry.php?ein=461031815" target='_blank'>
                      <img alt="" src="https://donate.savethestorks.com/images/ministry-watch-1C-logo-1-e1710520853768.webp" width={150} height={36}/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
  }
}