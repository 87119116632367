import React from 'react'
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap'
import { 
  CardNumberElement, 
  CardExpiryElement, 
  CardCvcElement,
} from '@stripe/react-stripe-js'
import Spinner from 'sharedComponents/Spinner'
import InputWrapperByShowTitle from 'sharedComponents/InputWrapperByShowTitle'

const contentStyle = (inputSize) => ({
  base: {
    color: '#555',
    backgroundColor: '#e8f0fe',
    lineHeight: inputSize === 'lg' ? '26px' : '20px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: inputSize === 'lg' ? '18px' : '14px',
    '::placeholder': {
      color: 'grey'
    }
  },
  invalid: {
    color: '#a94442',
    iconColor: '#a94442'
  }
})

export default class StripeCardBody extends React.Component {

  render() {
    const {
      stripeLoaded,
      displayValidationError,
      card,
      // children,
      // ctaText,
      enterCard,
      // ctaOnClick,
      editMode,
      // onClose,
      // loading,
      paymentErrors,
      layoutConfig,
    } = this.props
    const errors = paymentErrors || []
    const showInputTitles = layoutConfig?.paymentDetails?.showInputTitles
    const inputSize = layoutConfig?.paymentDetails?.inputSize
    const ContentStyle = contentStyle(inputSize)
    const extraColClass = `for-${inputSize}-input`

    return stripeLoaded ? (
      <Row className="mt2">
        <Col xs={12} sm={6} className={`${(displayValidationError && !card.cardNumber) ? 'has-error' : ''} ${extraColClass}`}>
          {/* <label className="pre-field required">Card Number</label> */}
          <InputWrapperByShowTitle showInputTitles={showInputTitles} showFloatingLabel={false} title="Card Number">
            <CardNumberElement options={{style: ContentStyle}} />
          </InputWrapperByShowTitle>
        </Col>
        <Col xs={12} sm={6} className={`${(displayValidationError && !card.month) ? 'has-error' : ''} ${extraColClass}`}>
          {/* <label className="pre-field required">Card Expiry</label> */}
          <InputWrapperByShowTitle showInputTitles={showInputTitles} showFloatingLabel={false} title="Card Expiry">
            <CardExpiryElement options={{style: ContentStyle}} />
          </InputWrapperByShowTitle>
        </Col>
        <Col xs={12} sm={6} className={`${(displayValidationError && !card.cardCode) ? 'has-error' : ''} ${extraColClass}`}>
          {/* <label className="pre-field required">Code</label> */}
          <InputWrapperByShowTitle showInputTitles={showInputTitles} showFloatingLabel={false} title="CVC/CVV">
            <CardCvcElement placeholder={showInputTitles ? "123" : "CVC/CVV"} options={{style: ContentStyle}} />
          </InputWrapperByShowTitle>
        </Col>
        <Col xs={12} sm={6} className={(displayValidationError && errors.some(e => e.field === 'card.fullName')) ? 'has-error' : ''}>
          {/* <label className="pre-field required">Name on Card</label> */}
          <InputWrapperByShowTitle showInputTitles={showInputTitles} title="Name on Card">
          <Form.Control
            type="text"
            // className="mb1"
            title="Name on Card"
            name="card.fullName"
            value={card.fullName}
            onChange={e => {
              enterCard({'fullName': e.target.value.trimStart()})
              e.currentTarget.classList.remove('is-invalid')
              e.currentTarget.parentElement?.classList.remove('has-error')
              e.currentTarget.parentElement?.parentElement?.classList.remove('has-error')
            }}
            autoComplete="cc-name"
            maxLength={22}
            placeholder={showInputTitles ? "Full Name" : "Name on Card"}
            style={{ fontFamily: 'sans-serif', color: 'black', marginBottom: 5 }}
            isInvalid={displayValidationError && errors.some(e => e.field === 'card.fullName')}
            size={inputSize}
          />
          
            <Form.Control.Feedback type="invalid" >
              <div style={displayValidationError ? null : {display: 'none'}}>
              {errors.find(e => e.field === 'card.fullName')?.message[0]}
              </div>
            </Form.Control.Feedback>
          </InputWrapperByShowTitle>
        </Col>
        {/* {
          editMode ? (
            <Col xs={12}>
            <div className="modal-footer edit-mode">
              <Button 
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="btn-donate float-right"
                onClick={e => ctaOnClick(e, this.props)}
                disabled={loading}
              >
                Submit
              </Button>
            </div>
            </Col>
          ) : (
            <Col xs={12}>
              { children }
            </Col>
          )
        } */}
      </Row>
    ) : <Spinner />
  }
}
